export default function ForLoop(props: any) {
  if (props.items.length === 0) {
    if (props.ifNoItems) {
      return props.ifNoItems
    }
    return null
  }
  return props.items.map((item: any, index: number) => {
    return props.forEachItem(item, index)
  })
}
